<template>
<div class="staff-mapping-container">
  <div v-if="mappingDisabled">
    <div class="text-container" >
      <p> {{$t("no_staff_available_for_mapping")}}</p>
    </div>
  </div>
  <div v-else-if="!mappingDisabled">
    <div v-if="addEnabled">
      <div class="pull-right" >
        <Button id="addBtn" @click="openAddStaffMappingForm" :label="$t(`add_staff_mapping`)"> </Button>
      </div>
      <div class="text-container" >
        <p> {{$t("click_on_the_add_button_to_add_staff_mapping")}}</p>
      </div>
    </div>
    <div v-else-if="!addEnabled">
      <div v-if="!updateStaffMappingView">
        <div class="pull-right">
          <Button id="updateBtn" @click="openEditStaffMappingForm" :label="$t(`update_staff_mapping`)"> </Button>
        </div>
        <Form key="StaffMappingView" name="StaffMapping" :is-editing="editMode" :loadFormData="true" :getFormUrlParams="'idName=PATIENT&id=' + patientId" :saveFormUrlParams="'?patientId=' + patientId" />
      </div>
      <div v-else-if="updateStaffMappingView">
        <div class="pull-right">
          <Button id="cancelBtn" @click="back" :label="$t(`cancel`)" type="outlined">  </Button>
        </div>
        <Form key="StaffMappingUpdate" name="StaffMapping" :is-editing="editMode" :loadFormData="true" :getFormUrlParams="'idName=PATIENT&id=' + patientId" :saveFormUrlParams="'?patientId=' + patientId" />
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Form from '../../../../shared/components/Form.vue'
import Button from '../../../../shared/components/Button.vue'
import EventBus from '@/app/shared/event-bus.js'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'StaffMapping',
  components: {
    Form,
    Button
  },
  mounted () {
    this.inializeConfig()
  },
  computed: {
    ...mapState('Form', ['isLoading']),
    ...mapState('Patient', ['patientId'])
  },
  data () {
    return {
      addEnabled: false,
      mappingDisabled: false,
      updateStaffMappingView: false,
      editMode: false,
      redirectedFromAddForm: false
    }
  },
  created () {
    EventBus.$on('FORM_SAVE_SUCCESS', this.formSavedSuccessfully)
  },
  destroyed () {
    EventBus.$off('FORM_SAVE_SUCCESS', this.formSavedSuccessfully)
  },
  methods: {
    async inializeConfig () {
      const response = await this.getStaffMappingTab(this.patientId)
      this.addEnabled = response.Data.EnableAdd
      this.mappingDisabled = response.Data.MappingDisabled
    },
    openUpdateStaffMappingForm () {
      if (this.isLoading) {
        return
      }
      this.addEnabled = false
      this.updateStaffMappingView = true
      this.editMode = true
    },
    openAddStaffMappingForm () {
      this.openUpdateStaffMappingForm()
      this.redirectedFromAddForm = true
    },
    openEditStaffMappingForm () {
      this.openUpdateStaffMappingForm()
      this.redirectedFromAddForm = false
    },
    back () {
      if (this.isLoading) {
        return
      }
      this.addEnabled = this.redirectedFromAddForm && true
      this.updateStaffMappingView = false
      this.editMode = false
      this.inializeConfig()
    },
    formSavedSuccessfully (response) {
      this.back()
    },
    ...mapActions('Form', ['loadForm']),
    ...mapActions('StaffMapping', ['getStaffMappingTab'])
  }
}
</script>

<style lang="scss" scoped>

.pull-right {
  justify-content: flex-end;
  display: flex;
  margin: 16px;
}
.text-no-margin {
  margin: auto;
}
.title-container {
  height: 100%;
  padding: 16px;
  background: $gray;
  border: 1px solid $light-gray;
  box-sizing: border-box;
  border-radius: 12px 12px 0px 0px;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.005em;
}
.staff-mapping-container{
  height: 100%;
  width: 100%;
  background-color: var(--background-color-primary-light-dark);
  display: table;
}
.staff-mapping-container:hover {
  box-shadow: 0 8px 16px 0 $shadow;
}
.text-container {
  background-color: var(--background-color-primary-light-dark) ;
  padding: 10px 20px 30px 10px;
  letter-spacing: 0.015em;
  box-sizing: border-box;
  border-radius: 0px 12px 12px 12px;
  color: var(--text-primary-color);
}
</style>
